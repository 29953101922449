.peearz-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 55%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
    text-align: justify;
}

@media screen and (max-width: 1200px) {

    .peearz-zone {
        text-align: justify;
    }

    .peearz-zone {
        position: initial;
        transform: none;
        width: 100%;
        display: block;
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
        margin-top: 100px
    }
}