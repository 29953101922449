.pe-photo {

    z-index: 0;
    width: 380px;
    position: absolute;
    top: 10%;
    right: 5%;
    bottom: auto;
    left: auto;
    margin: auto;
    margin-top: 180px;
    animation: fadeIn 6s;
  
    img {
        width: 100%;
        height: auto;
        bottom: 0;
        border-radius: 10px;
    }
}

@media screen and (max-width: 1200px) {
    .pe-photo {

        position: initial;
        width: 70%;
        height: auto;
        top: 10%;
        right: 0;
        box-sizing: border-box;
        margin: auto ;
        left: 0;
    
    
        img {
            width: 100%;
            height: auto;
            bottom: 0;
        }
    }
}