.nav-bar {
  background: #181818;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 500px;

  .logo {
    display: block;
    padding: 4px 0;
    margin-bottom: 0%;

    img {
      display: block;
      margin: auto;
      width: 60px;
      height: auto;

      &.sub-logo {
        width: 56px;
        margin-top: 0px;
      }
    }
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

    a {
      font-size: 22px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #ffd700;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &:after {
          content: 'HOME';
        }
      }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.portfolio-link {
      &:after {
        content: 'PORTFOLIO';
      }
    }

    a.active {
      svg {
        color: #ffd700;
      }
    }
  }

  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 15px;
        line-height: 16px;
        color: #4d4d4e;
      }

      &:hover {
        .anchor-icon {
          color: #ffd700;
        }
      }
    }
  }
}

.hamburger-icon, .close-icon {
  display: none;
}

    
@media screen and (max-width: 1200px) {
  .nav-bar { 

    width: 100%;
    height: 65px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    min-height: 0;

      // width: 100%;
      // position: fixed;
      // left: 100%;
      // opacity: 0;
      // margin-left: 0;
      // float: left;
      // overflow: hidden;
      // text-align: center;
      // height: 60px;
      // top: 60px;
      // margin-top: 0;
      // background: #181818;
      // -webkit-transition: all .3s ease-out;
      // transition: all .3s ease-out;

    .logo {
      display: block;
      padding: 8px 0;
      width: 10%;

      img {
          display: block;
          margin: -7px 1.2px;
          width: 50px;
          height: auto;

          &.sub-logo {
              width: 38px;
              margin: 3px 6px;
          }
      }
  }

      ul, nav {
      display: none;
      // display: flex;
      // justify-content: space-evenly;
      }

      nav {
      width: 100%;
      top: 65px;
      left: 0;
      background: #181818;
      height: 45px;
      margin: 0;
      z-index: 2;
      position: fixed;
      overflow: hidden;

      li {
        a {
          padding: 7px 0;
          display: block;
          font-size: 15px;
          line-height: 16px;
          color: #4d4d4e;
        }
  
        &:hover {
          .anchor-icon {
            color: #ffd700;
          }
        }
      }



      &.mobile-show {
        display: flex;
        justify-content: space-evenly;
        // border: 3px solid gold;

      }

      a {
        display: flex;
        // justify-content: center;
        // align-items: flex-start;
      }

      a:after {
        opacity: 1;
        position: initial;
        width: auto;
        margin-top: 5px;
        margin-left: -28PX;
      }

      a svg {
        opacity: 1 !important;
      }
      }

      .hamburger-icon,
      .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        display: block;

      }

  }
}





