.port-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 86%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
}

.dabba {
    // display: flex;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;

    // flex-direction: row;
    // justify-content: space-evenly;
}

.boxes {
    margin: 5px;
}

.card {
    width: 30rem;
    margin: 5px;
    background-color: #022c43;
    overflow: hidden;
    position: relative;
    margin-right: 3rem;
    margin-left: 3rem;
    transition: 0.8s;
    border-radius: 6px;
    display: inline-block;

    img {
        width: 100%;
        height: 17rem;
    }
}

.card:hover {
    transform: scale(1.12);
}


.card-heading {
    color: #fff;
}

.card-body {
    color: #fff;
}

.card-dropdown {
    position: absolute;
    background-color: #2d3b44ab;
    z-index: 0;
    bottom: 0;
    transform: translateY(40%);
    transition: 0.3s;
    text-align: center;
    align-items: center;
    width: 100%;
}

.card:hover .card-dropdown {
    transform: translateY(0%);
}

h2 {
    margin-top: 3px;
    margin-bottom: 0;
}

h3 {
    margin-top: 0%;
    margin-bottom: 4px;
}


@media screen and (max-width: 1200px) {

    .tags,
    .portfolio-page h1:before,
    .portfolio-page h1:after {
        display: none;
    }


    .container {
        position: initial;
        height: auto;
        min-height: auto;

        .port-page .text-zone {
            position: initial;
            width: 100%;
            transform: none;
            padding: 10px;
            box-sizing: border-box;
            text-align: center;

        }
    }

    .port-zone {
        text-align: center;
        margin-top: 105px;
    }

    .dabba {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card {
        margin-bottom: 10px;
    }

    // .card-dropdown {
    //     transform: translateY(0%);
    // }
}